import MultiCheckboxSelection from 'components/general/Control/MultiCheckboxSelection'
import {Col} from 'reactstrap'
import {useGetCategoryPaginationQuery} from 'store/slices/category.js'
import {useMemo} from 'react'

const CategoriesWorkout = ({required, showError, selected, updateField}) => {
    const {data} = useGetCategoryPaginationQuery({page: 0, limit: 100})

    const categoryOptions = useMemo(() => {
        return data?.data?.map((c) => ({
            label: c.display_name,
            value: c.code,
        }))
    }, [data])

    return (
        <Col sm="12" lg="6" md="6" className="pl-0 pr-0 pr-sm-0 pr-md-3">
            <MultiCheckboxSelection
                required={required}
                showError={showError}
                label={'Categories'}
                options={categoryOptions}
                selected={selected}
                onChange={(codes) => {
                    const newCategories = codes.map((code) =>
                        data?.data?.find((opt) => code === opt.code)
                    )

                    updateField('categories', newCategories)
                }}
            />
        </Col>
    )
}

export default CategoriesWorkout
