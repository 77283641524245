import {createApi} from '@reduxjs/toolkit/query/react'
import {
    axiosBaseQuery,
    transformResponseFromData,
    provideTags,
    provideTag,
} from 'store/common'

const authorApi = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: 'author',
    tagTypes: ['Authors', 'Author'],
    endpoints: (build) => ({
        getAuthorPagination: build.query({
            query: (params) => ({
                url: 'admin/author',
                method: 'GET',
                params,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('Authors', data?.data),
        }),
        getAuthorById: build.query({
            query: ({id, language = 'en'}) => ({
                url: `admin/author/${id}?language=${language}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Author', data, '_id')],
        }),
        saveAuthor: build.mutation({
            query: (data) => ({
                url: 'admin/author',
                method: 'POST',
                body: data,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Author', data, '_id')],
            invalidatesTags: (_, __, data) => [
                {type: 'Authors', id: data?._id},
                {type: 'Author', id: data?._id},
            ],
        }),
    }),
})

export default authorApi

export const {
    useGetAuthorPaginationQuery,
    useGetAuthorByIdQuery,
    useSaveAuthorMutation,
} = authorApi
