import {
    useEffect,
    useMemo,
    useState,
    useUrlParams,
    useDispatch,
} from 'services/hooks'
import {Page} from 'components/general/Page'
import {TableWrapperV2} from 'components/general/Display/TableV2'
import {
    useGetThemePaginationQuery,
    useSetDefaultThemeMutation,
} from 'store/slices/theme'
import {Col, Row} from 'reactstrap'
import {Button, SelectInput} from 'components/general/Control'
import {styled} from 'common'
import {showAlertMessage} from 'services/utils.js'

const Themes = () => {
    const dispatch = useDispatch()
    const [urlParams] = useUrlParams()
    const {page_number = 0, page_size = 10} = urlParams

    const [oldThemeId, setOldThemeId] = useState('')
    const [defaultThemeId, setDefaultThemeId] = useState('')
    const {data: themeOptsRes} = useGetThemePaginationQuery({
        page: 0,
        limit: 100,
    })

    const themesRes = useGetThemePaginationQuery({
        page: page_number,
        limit: page_size,
    })

    const [doSetTheme, {isLoading}] = useSetDefaultThemeMutation()

    const themeData = useMemo(() => {
        return themesRes?.data?.data?.map((prop) => {
            let name = prop.name || prop._id
            if (prop.is_default) {
                name += ` | Default Theme`
            }
            return {
                id: prop.id,
                _id: prop._id,
                name,
                pathname: `/admin/theme/${prop._id}`,
            }
        })
    }, [themesRes?.data?.data])

    const themeOptsData = useMemo(() => {
        return themeOptsRes?.data?.map((prop) => ({
            label: prop.name,
            value: prop._id,
        }))
    }, [themeOptsRes])

    useEffect(() => {
        if (themeOptsRes?.data?.length) {
            const df = themeOptsRes.data?.find((t) => t.is_default)
            setDefaultThemeId(df._id)
            setOldThemeId(df._id)
        }
    }, [themeOptsRes])

    const handleSetDefault = async () => {
        try {
            await doSetTheme({themeId: defaultThemeId})
            showAlertMessage(
                'success change default theme',
                'alert-success',
                dispatch
            )
        } catch (err) {
            showAlertMessage(
                `failed change default theme. ${err}`,
                'alert-success',
                dispatch
            )
        }
    }

    return (
        <Page>
            <Wrapper>
                <Col sm={'6'}>
                    <SelectInput
                        label={'Default Theme'}
                        name={'default_theme'}
                        options={themeOptsData}
                        selected={defaultThemeId}
                        onChange={setDefaultThemeId}
                        disabled={isLoading}
                    />
                </Col>
                <Col sm={'12'}>
                    <Button
                        id="set_default_button"
                        color="primary"
                        type="button"
                        onClick={handleSetDefault}
                        disabled={isLoading || oldThemeId === defaultThemeId}>
                        Set Default Theme
                    </Button>
                </Col>
            </Wrapper>
            <TableWrapperV2
                title="Themes"
                isFetching={themesRes.isFetching}
                createNew={'/admin/theme/new'}
                data={themeData}
                metadata={themesRes?.data?.metadata}
                columns={[{Header: 'Theme Name', accessor: 'name'}]}
            />
        </Page>
    )
}

const Wrapper = styled(Row)`
    padding: 80px 30px 0 130px;
    @media (max-width: 1200px) {
        padding: 80px 10px 0;
    }
`

export default Themes
