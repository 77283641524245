import {skipToken} from '@reduxjs/toolkit/dist/query'
import {
    useCallback,
    useDispatch,
    useEffect,
    useHistory,
    useParams,
    useState,
} from 'services/hooks'
import {
    useGetCategoryByIdQuery,
    useSaveCategoryMutation,
} from 'store/slices/category.js'
import {
    createFormData,
    getSuccessSaveMessageData,
    showAlertMessage,
} from 'services/utils.js'
import {Language} from 'components/general/Display'
import {Col, FormGroup, Label, Row} from 'reactstrap'
import {Button, Input} from 'components/general/Control'
import React from 'react'
import {ImageUpload} from '../Details/index.js'
import PopoverColorPicker from '../general/Display/PopoverColorPicker.js'
import styled from 'styled-components'

const CategoryDetail = ({onError}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()
    const isNew = params?.id === 'new'

    const [language, setLanguage] = useState('en')

    const {data: categoryData} = useGetCategoryByIdQuery(
        isNew ? skipToken : {id: params?.id, language}
    )

    const [saveCategory, {isSuccess}] = useSaveCategoryMutation()

    const [updatedCategory, setUpdatedCategory] = useState({})
    const [imageFile, setImageFile] = useState({file: null, previewUrl: null})

    const redirectToList = useCallback(
        (redirect = false) => redirect && history.goBack(),
        [history]
    )

    const onSave = (e) => {
        e?.preventDefault()

        const formData = createFormData({
            image: imageFile.file || imageFile.previewUrl,
            name: updatedCategory.name,
            display_name: updatedCategory.display_name,
            color: updatedCategory.color,
            icon: updatedCategory.icon,
            language: language,
        })

        if (!isNew) {
            formData.append('_id', categoryData._id)
        }

        saveCategory(formData)
            .unwrap()
            .catch((err) => onError(err?.data?.message))
    }

    useEffect(() => {
        if (categoryData) {
            setUpdatedCategory(categoryData)
            setImageFile({previewUrl: categoryData?.image, file: null})
        }
    }, [categoryData])

    useEffect(() => {
        if (isSuccess) {
            const {message, type, redirect} = getSuccessSaveMessageData(
                updatedCategory?.name
            )

            showAlertMessage(message, type, dispatch)
            redirect && redirectToList(true)
        }
    }, [dispatch, isSuccess, redirectToList, updatedCategory])

    return (
        <>
            <Row>
                <Language
                    sm={6}
                    className={''}
                    language={language}
                    setLanguage={setLanguage}
                    disabled={isNew}
                />
            </Row>

            <Row>
                <Col sm="6">
                    <FormGroup>
                        <Label>NAME</Label>
                        <Input
                            name="name"
                            value={updatedCategory?.name}
                            setter={(v) => {
                                setUpdatedCategory((old) => ({...old, name: v}))
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label>DISPLAY NAME</Label>
                        <Input
                            name="display_name"
                            value={updatedCategory?.display_name}
                            setter={(v) => {
                                setUpdatedCategory((old) => ({
                                    ...old,
                                    display_name: v,
                                }))
                            }}
                        />
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label>COLOR</Label>
                        <Input
                            id="color"
                            name="color"
                            value={updatedCategory?.color}
                            setter={(v) => {
                                setUpdatedCategory((old) => ({
                                    ...old,
                                    color: v,
                                }))
                            }}
                        />
                    </FormGroup>
                    <PopoverColorPicker
                        target="color"
                        color={updatedCategory?.color}
                        onColorChange={(_, color) => {
                            setUpdatedCategory((old) => ({...old, color}))
                        }}
                    />
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label>ICON</Label>
                        <Input
                            name="icon"
                            value={updatedCategory?.icon}
                            setter={(v) => {
                                setUpdatedCategory((old) => ({
                                    ...old,
                                    icon: v,
                                }))
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <ImageUploadContainer>
                <ImageUpload
                    imageFile={imageFile}
                    setImage={setImageFile}
                    title="Main Image"
                />
            </ImageUploadContainer>

            <Row className="d-flex justify-content-center pt-4">
                <Button
                    id="save_button"
                    color="primary"
                    type="button"
                    onClick={onSave}>
                    {isNew ? 'CREATE' : 'MODIFY'} CATEGORY
                </Button>
            </Row>
        </>
    )
}

const ImageUploadContainer = styled.div`
    min-height: 350px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
`

export default CategoryDetail
