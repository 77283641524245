import {ChromePicker} from 'react-color'
import {PopoverBody, PopoverHeader, UncontrolledPopover} from 'reactstrap'

const PopoverColorPicker = ({target, onColorChange, color}) => {
    return (
        <UncontrolledPopover
            placement="bottom"
            target={target}
            trigger="legacy">
            <PopoverHeader>Pick Color</PopoverHeader>
            <PopoverBody>
                <ChromePicker
                    defaultView={'rgb'}
                    color={color}
                    onChange={(v) => {
                        const {r, g, b, a} = v.rgb
                        const newColor = `rgba(${r},${g},${b},${a})`
                        onColorChange(target, newColor)
                    }}
                />
            </PopoverBody>
        </UncontrolledPopover>
    )
}

export default PopoverColorPicker
