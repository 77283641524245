import {Page} from 'components/general/Page/index'
import {TableWrapperV2} from 'components/general/Display/TableV2'
import {useUrlParams, useMemo} from 'services/hooks'
import {useGetCategoryPaginationQuery} from 'store/slices/category'

const columns = [
    {Header: 'Name', accessor: 'name'},
    {Header: 'Display Name', accessor: 'display_name'},
]

const Categories = () => {
    const [urlParams] = useUrlParams()
    const {page_number, page_size} = urlParams
    const {data: categories} = useGetCategoryPaginationQuery({
        page: page_number,
        limit: page_size,
        language: 'en',
    })

    const dataCategories = useMemo(() => {
        return categories?.data?.map((cat) => ({
            id: cat._id,
            name: cat.name,
            display_name: cat.display_name,
            image: cat.image,
            color: cat.color,
            icon: cat.icon,
            pathname: `/admin/category/${cat._id}`,
        }))
    }, [categories?.data])

    return (
        <Page>
            <TableWrapperV2
                title="Categories"
                isFetching={false}
                createNew={'/admin/category/new'}
                data={dataCategories}
                metadata={categories?.metadata}
                columns={columns}
            />
        </Page>
    )
}
export default Categories
