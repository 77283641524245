/* eslint-disable complexity */
import {userRoleOptions} from 'constant/user'
import {setMessage} from 'store/store'

import * as uuid from 'uuid'
import moment from 'moment'
import {TARGET_REPETITIONS, TYPE_EXERCISE} from 'constant/workout'

const imageTypes = ['image', 'tall_image', 'small_image']

export const createFormData = (data) => {
    const formData = new FormData()
    Object.keys(data).forEach((key) => {
        if (
            key &&
            !(data[key] instanceof File) &&
            (Array.isArray(data[key]) || typeof data[key] === 'object')
        ) {
            formData.append(key, JSON.stringify(data[key]))
        } else if (key) {
            formData.append(key, data[key])
        }
    })
    return formData
}

export const formatDataToDragAndDrop = (items) => {
    return items?.map((item) => ({
        name: item.name,
        id: item._id || item.id,
        uuid: uuid.v4(),
    }))
}

export const formatDataToDragAndDropFullObject = (items) => {
    return (
        items?.map((item) => ({
            uuid: uuid.v4(),
            activity_type: item?.activity_type || 'exercise',
            ...item,
        })) || []
    )
}

const isNonNullObject = (obj) => typeof obj === 'object' && obj !== null
const isEmptyObject = (obj) =>
    isNonNullObject(obj) && Object.keys(obj).length === 0
const isEmptyArray = (arr) => Array.isArray(arr) && arr.length === 0

export const checkValidFields = (fields) => {
    const notNullObject = isNonNullObject(fields)

    if (!fields) return false
    if (isEmptyArray(fields) || isEmptyObject(fields)) {
        //TODO: MAKE SURE OF WE WANT THIS BEHAVIOUR
        return true
    }
    const fieldsToCheck = notNullObject ? Object.values(fields) : fields
    return fieldsToCheck.every((value) => {
        if (Array.isArray(value) || isNonNullObject(value)) {
            return checkValidFields(value)
        }
        return Boolean(value) || value === false || value === 0
    })
}

export const validateNumberInput = (value) => {
    if (!value.match(/^[0-9]*$/)) {
        return false
    } else {
        return true
    }
}

export const showAlertMessage = (message, type, dispatch) => {
    dispatch(
        setMessage({
            message,
            type,
        })
    )
}

export const getSuccessSaveMessageData = (name) => {
    const message = `${name} has been saved!`
    let type = 'alert-success'
    let redirect = true

    return {message, type, redirect}
}

export const isNull = (value) => !value && value !== 0 && value !== false

export const isBarException = (field, exercise) =>
    (exercise?.exercise_type === 'bar' &&
        exercise?.bar_mode === 'performance' &&
        field === 'anchor_location') ||
    (exercise?.bar_mode === 'strength' && field === 'anchor_orientation')

export const getIsEmptyField = (field, data, isExercise) => {
    const value = data[field]

    if (isExercise) {
        if (isBarException(field, data)) {
            return false
        }
    }
    if (isNull(value)) {
        console.log('missing field: ', field, 'value: ', value)
        return true
    }

    const isImage = imageTypes.includes(field)
    if (isImage && !value?.previewUrl) {
        console.log('missing field: ', field, 'value: ', value?.previewUrl)
        return true
    }

    return false
}

export const getIsEmptyMandatoryField = (fields, data, exerciseType) => {
    const isEmptyGeneralField = fields.general.some((field) => {
        return getIsEmptyField(field, data)
    })
    if (!exerciseType) {
        return !isEmptyGeneralField
    } else {
        const isEmptyByTypeField = fields.per_type[exerciseType]?.some(
            (field) => {
                return getIsEmptyField(field, data, true)
            }
        )
        return !isEmptyGeneralField && !isEmptyByTypeField
    }
}

export const getIsActiveState = (toggleActive, data) => {
    const isActivated = !data.active && toggleActive
    const isDeactivated = data.active && toggleActive
    if (isDeactivated) {
        return false
    }
    return isActivated || data.active
}

export const saveFile = (data, filename) => {
    const blob = new Blob([data], {type: 'text/csv'})
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.download = filename
    link.href = url
    link.click()
    return Promise.resolve()
}

export const getFieldDisplayValue = (field, data) => {
    if (field === 'instructions') {
        return data?.instructions || []
    }
    return data[field]
}
export const getTranslationData = (data, language, activityId) => {
    const fields = ['display_name', 'name', 'instructions']
    return fields.map((field) => ({
        field,
        object_id: activityId,
        language,
        display_value: getFieldDisplayValue(field, data),
    }))
}

export const getInstructions = (activity) =>
    Array.isArray(activity?.instructions)
        ? activity.instructions[0]
        : activity.instructions || ''

export const getFieldAsNumberOrZero = (field) => (field ? Number(field) : 0)

export const getDuplicatedNamesMap = (data) => {
    const duplicationMap = data.reduce((map, exercise) => {
        const key = exercise.name.toLowerCase()
        map[key]
            ? (map[key] = [...map[key], exercise.id])
            : (map[key] = [exercise.id])
        return map
    }, {})

    let duplicationList = []
    Object.keys(duplicationMap).filter((key) => {
        return (
            duplicationMap[key].length > 1 &&
            duplicationList.push({[key]: duplicationMap[key]})
        )
    })

    return duplicationList
}

export const getUserRole = (role) => {
    const userRole = userRoleOptions.find((option) => option.value === role)
    if (!userRole) {
        return userRoleOptions[0]
    }
    return userRole
}

export const getEntityDeletionAlertProps = (
    entity,
    entityType,
    deletionAction
) => {
    const title = `Delete ${entityType}`
    const emptyFunction = () => {}
    const confirmBtnText = 'confirm'

    if (!entity || (entity.active && entity.version > 1)) {
        return {
            mainText: `This ${entityType} is Active. Please deactivate it and try again`,
            confirmBtnText,
            deactivate: emptyFunction,
            hideAlertOnConfirm: emptyFunction,
            title,
        }
    }
    return {
        mainText: `This ${entityType}`,
        confirmBtnText: `Delete ${entityType}`,
        deactivate: (id) => {
            deletionAction(id)
        },
        action: `deleted`,
        title,
    }
}

export const getIsInvalidNumber = (value, minValue) =>
    isNaN(value) || (value && value <= minValue)

export const getQuarters = (year = moment().year()) => {
    const raw = [
        {quarter: 1, startMonth: 0, endMonth: 2},
        {quarter: 2, startMonth: 3, endMonth: 5},
        {quarter: 3, startMonth: 6, endMonth: 8},
        {quarter: 4, startMonth: 9, endMonth: 11},
    ]

    const result = []
    raw.forEach((quarter) => {
        const start = moment({year, month: quarter.startMonth}).startOf('month')
        start.subtract(start.days(), 'days')
        const startDate = start.format('YYYY-MM-DD')

        var end = moment({year, month: quarter.endMonth}).endOf('month')
        end.subtract(end.days() + 1, 'days')
        const endDate = end.format('YYYY-MM-DD')

        result.push({
            quarter: quarter.quarter,
            startDate,
            endDate,
        })
    })

    return result
    /*
        {quarter: 1, startDate: '2023-12-31', endDate: '2024-03-30'},
        {quarter: 2, startDate: '2024-03-31', endDate: '2024-06-29'},
        {quarter: 3, startDate: '2024-06-30', endDate: '2024-09-28'},
        {quarter: 4, startDate: '2024-09-29', endDate: '2024-12-28'}
    */
}

export const getNextQuarter = (quarterEndDate) => {
    const lastQuarter = moment(quarterEndDate)

    const quarters = getQuarters(lastQuarter.year())

    const currentQuarter = quarters.find(
        (quarter) => quarter.endDate === lastQuarter.format('YYYY-MM-DD')
    )

    let nextQuarter
    if (currentQuarter.quarter < 4) {
        nextQuarter = quarters[currentQuarter.quarter]
    } else {
        const nextQuarters = getQuarters(lastQuarter.year() + 1)
        nextQuarter = nextQuarters[0]
    }

    return nextQuarter
}

export const calculateWorkout = (workoutActivities) => {
    let estimateWorkout = 0
    let totalWorkout = 0
    let totalTarget = 0
    workoutActivities?.forEach((wa) => {
        if (wa.workoutActivity) {
            estimateWorkout += recalculateEstimationWorkout(wa.workoutActivity)
            if (wa.workoutActivity.activity_type === TYPE_EXERCISE) {
                totalWorkout++
            }
            if (wa.workoutActivity.target_type === TARGET_REPETITIONS) {
                totalTarget +=
                    wa.workoutActivity.target * (wa.workoutActivity.sets || 1)
            }
        } else if (wa.group) {
            for (let round = 1; round <= wa?.group?.rounds; round++) {
                for (const act of wa?.group?.exercises) {
                    estimateWorkout += recalculateEstimationWorkout(act)
                    if (act.activity_type === TYPE_EXERCISE && round === 1) {
                        totalWorkout++
                    }
                    if (act.target_type === TARGET_REPETITIONS) {
                        totalTarget += act.target * (act.sets || 1)
                    }
                }
            }
        }
    })

    return {estimateWorkout, totalWorkout, totalTarget}
}

const recalculateEstimationWorkout = (activity) => {
    let estimateWorkout = 0
    const {rest_time = 0, work_time = 0, target = 0, target_type} = activity
    if (target_type === TARGET_REPETITIONS) {
        const repetitionEstimateTime = target * 3
        estimateWorkout += rest_time + repetitionEstimateTime
    } else {
        estimateWorkout += rest_time + work_time
    }

    return estimateWorkout
}

export const mergeObjectBetweenAtoB = (a, b = {}) => {
    if (typeof a !== 'object' || a === null) return b
    if (typeof b !== 'object' || b === null) b = {}

    const target = Object.assign({}, b)

    for (const key of Object.keys(a)) {
        if (
            typeof a[key] === 'object' &&
            a[key] !== null &&
            !Array.isArray(a[key])
        ) {
            // Ensure b[key] is an object before merging
            target[key] = mergeObjectBetweenAtoB(
                a[key],
                target[key] && typeof target[key] === 'object'
                    ? target[key]
                    : {}
            )
        } else {
            // Assign only if b[key] is missing or empty (handles strings, arrays, etc.)
            if (
                target[key] === undefined ||
                target[key] === null ||
                (Array.isArray(target[key]) && !target[key].length)
            ) {
                target[key] = a[key]
            }
        }
    }

    return target
}

export const cleanObject = (obj) => {
    if (typeof obj !== 'object' || obj === null) return obj
    if (Object.keys(obj).length === 0) return obj

    const result = {}

    for (const [key, value] of Object.entries(obj)) {
        if (
            value !== undefined &&
            value !== null &&
            !(typeof value === 'string' && value.trim() === '') &&
            !(Array.isArray(value) && value.length === 0)
        ) {
            // Recursively clean nested objects
            result[key] =
                typeof value === 'object' && !Array.isArray(value)
                    ? cleanObject(value)
                    : value
        }
    }

    return result
}
