import {skipToken} from '@reduxjs/toolkit/dist/query'
import {
    useCallback,
    useDispatch,
    useEffect,
    useHistory,
    useParams,
    useState,
} from 'services/hooks'
import {
    useGetAuthorByIdQuery,
    useSaveAuthorMutation,
} from 'store/slices/author.js'
import {
    createFormData,
    getSuccessSaveMessageData,
    showAlertMessage,
} from 'services/utils.js'
import {Col, FormGroup, Label, Row} from 'reactstrap'
import {Button, Input} from 'components/general/Control'
import React from 'react'
import {ImageUpload} from '../Details/index.js'
import styled from 'styled-components'

const AuthorDetail = ({onError}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()
    const isNew = params?.id === 'new'

    const {data: authorData} = useGetAuthorByIdQuery(
        isNew ? skipToken : {id: params?.id}
    )

    const [saveAuthor, {isSuccess}] = useSaveAuthorMutation()

    const [updatedAuthor, setUpdatedAuthor] = useState({})
    const [imageFile, setImageFile] = useState({file: null, previewUrl: null})

    const redirectToList = useCallback(
        (redirect = false) => redirect && history.goBack(),
        [history]
    )

    const onSave = (e) => {
        e?.preventDefault()

        const formData = createFormData({
            image: imageFile.file || imageFile.previewUrl,
            name: updatedAuthor.name,
        })

        if (!isNew) {
            formData.append('_id', authorData.id)
        }

        saveAuthor(formData)
            .unwrap()
            .catch((err) => onError(err?.data?.message))
    }

    useEffect(() => {
        if (authorData) {
            setUpdatedAuthor(authorData)
            setImageFile({previewUrl: authorData?.image, file: null})
        }
    }, [authorData])

    useEffect(() => {
        if (isSuccess) {
            const {message, type, redirect} = getSuccessSaveMessageData(
                updatedAuthor?.name
            )

            showAlertMessage(message, type, dispatch)
            redirect && redirectToList(true)
        }
    }, [dispatch, isSuccess, redirectToList, updatedAuthor])

    return (
        <>
            <Row>
                <Col sm="6">
                    <FormGroup>
                        <Label>NAME</Label>
                        <Input
                            name="name"
                            value={updatedAuthor?.name}
                            setter={(v) => {
                                setUpdatedAuthor((old) => ({...old, name: v}))
                            }}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <ImageUploadContainer>
                <ImageUpload
                    imageFile={imageFile}
                    setImage={setImageFile}
                    title="Main Image"
                />
            </ImageUploadContainer>

            <Row className="d-flex justify-content-center pt-4">
                <Button
                    id="save_button"
                    color="primary"
                    type="button"
                    onClick={onSave}>
                    {isNew ? 'CREATE' : 'MODIFY'} AUTHOR
                </Button>
            </Row>
        </>
    )
}

const ImageUploadContainer = styled.div`
    min-height: 350px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
`

export default AuthorDetail
