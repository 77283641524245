import {FormGroup} from 'components/general/Control'
import RadioButtonsInput from 'components/general/Control/RadioButtonsInput'
import {PROGRAM_TYPES_OPTIONS} from 'constant/program'
import React, {useEffect} from 'react'

const ProgramType = ({type, handleProgramTypeChange}) => {
    useEffect(() => {
        if (!type) {
            handleProgramTypeChange(PROGRAM_TYPES_OPTIONS[0].value)
        }
    }, [handleProgramTypeChange, type])

    return (
        <FormGroup>
            <RadioButtonsInput
                noBorder
                selected={type}
                name={'program_type'}
                label={'Program Stage Type'}
                options={PROGRAM_TYPES_OPTIONS}
                onChange={handleProgramTypeChange}
            />
        </FormGroup>
    )
}

export default ProgramType
