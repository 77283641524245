import React, {useMemo} from 'react'
import {Col} from 'reactstrap'
import {useGetAuthorPaginationQuery} from 'store/slices/author.js'
import {SelectInput} from 'components/general/Control'

const CreatedByAuthor = ({setAuthor, selectedAuthor, setSelectedAuthor}) => {
    const {data: authorData} = useGetAuthorPaginationQuery({
        page: 0,
        limit: 100,
    })

    const authorOptions = useMemo(() => {
        if (Object.keys(authorData?.data || {}).length) {
            return authorData?.data?.map((at) => ({
                value: at._id,
                label: at.name,
            }))
        }

        return []
    }, [authorData])

    return (
        <>
            <Col sm={'6'} className="pl-0">
                <SelectInput
                    name="author_data"
                    label="Author"
                    options={authorOptions}
                    selected={selectedAuthor}
                    onChange={(v) => {
                        const author = authorData?.data?.find(
                            (a) => a._id === v
                        )
                        setAuthor(author.name)
                        setSelectedAuthor(author)
                    }}
                />
            </Col>
        </>
    )
}

export default CreatedByAuthor
