import {useState} from 'react'
import {DetailsWrapper, Page, PageContent} from 'components/general/Page'
import AuthorDetail from 'components/Author/AuthorDetail'
import MessageAlert from 'components/general/Display/MessageAlert'

const Author = () => {
    const [showAlert, setShowAlert] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const onError = (error) => {
        setShowAlert(true)
        setErrorMessage(error)
    }

    return (
        <Page>
            <PageContent>
                <DetailsWrapper
                    header="Author Detail"
                    backButtonText="Back to Authors">
                    <AuthorDetail onError={onError} />
                </DetailsWrapper>
            </PageContent>
            <MessageAlert
                showAlert={showAlert}
                title={'Failed To Save'}
                mainText={errorMessage}
                confirmBtnText="OK"
                onConfirm={() => setShowAlert(false)}
            />
        </Page>
    )
}
export default Author
