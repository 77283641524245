import {Col, FormGroup, Label, Row} from 'reactstrap'
import {
    useCallback,
    useDispatch,
    useEffect,
    useHistory,
    useParams,
    useState,
} from 'services/hooks'
import AppInput from 'components/general/Control/Input'
import {Button} from 'components/general/Control'
import {useGetThemeByIdQuery, useSaveThemeMutation} from 'store/slices/theme'
import {skipToken} from '@reduxjs/toolkit/dist/query'
import {
    cleanObject,
    getSuccessSaveMessageData,
    mergeObjectBetweenAtoB,
    showAlertMessage,
} from '../../services/utils.js'
import {JsonEditor} from 'json-edit-react'
import React, {useMemo} from 'react'
import {initialData} from './InitialState.js'

const ThemeDetail = ({onError}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()
    const isNew = params.id === 'new'

    const {data: themeData} = useGetThemeByIdQuery(
        isNew ? skipToken : {id: params.id}
    )
    const [doSaveTheme, {isSuccess, isLoading}] = useSaveThemeMutation()
    const [updatedTheme, setUpdatedTheme] = useState({})

    useEffect(() => {
        if (themeData) {
            const theme = themeData?.theme
            const result = {
                ...theme,
            }
            setUpdatedTheme(result)
        }
    }, [themeData])

    const updateAccountTheme = (field, value) => {
        setUpdatedTheme((prev) => ({...prev, [field]: value}))
    }

    const handleSaveTheme = (e) => {
        e?.preventDefault()

        const clean = cleanObject(updatedTheme)

        doSaveTheme(clean)
            .unwrap()
            .catch((r) => {
                onError(r?.data?.message)
            })
    }

    const redirectToList = useCallback(
        (redirect = false) => redirect && history.goBack(),
        [history]
    )

    useEffect(() => {
        if (isSuccess) {
            const {message, type, redirect} = getSuccessSaveMessageData(
                updatedTheme.name
            )

            showAlertMessage(message, type, dispatch)
            redirect && redirectToList(true)
        }
    }, [dispatch, isSuccess, redirectToList, updatedTheme])

    const dataJsonEditor = useMemo(() => {
        const result = {...updatedTheme}
        delete result.deleted
        delete result.updated
        delete result.created
        delete result.__v
        delete result._id
        delete result.is_default

        return mergeObjectBetweenAtoB(initialData, result)
    }, [updatedTheme])

    return (
        <div>
            <Row>
                <Col sm="12">
                    <FormGroup>
                        <Label>Theme Name</Label>
                        <AppInput
                            disableAutoComplete
                            id="name"
                            name="name"
                            value={updatedTheme?.name}
                            setter={(v) => updateAccountTheme('name', v)}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row>
                <Col className="pl-0" sm="12">
                    <JsonEditor
                        data={dataJsonEditor}
                        minWidth="100%"
                        setData={(v) => {
                            setUpdatedTheme((old) => ({...old, ...v}))
                        }}
                    />
                </Col>
            </Row>

            <Row className="d-flex justify-content-center pt-4">
                <Button
                    id="save_button"
                    color="primary"
                    type="button"
                    onClick={handleSaveTheme}
                    disabled={isLoading}>
                    Save Theme
                </Button>
            </Row>
        </div>
    )
}
export default ThemeDetail
