import {Col, FormGroup, Label, Row} from 'reactstrap'
import {
    useCallback,
    useDispatch,
    useEffect,
    useHistory,
    useParams,
    useState,
    useMemo,
} from 'services/hooks'
import {
    useGetAccountQuery,
    useSaveAccountMutation,
    useGetThemePaginationQuery,
} from 'store/store'
import AppInput from 'components/general/Control/Input'
import {Button, SelectInput} from 'components/general/Control'
import {getSuccessSaveMessageData, showAlertMessage} from 'services/utils.js'

const AccountDetail = ({onError}) => {
    const dispatch = useDispatch()
    const params = useParams()
    const history = useHistory()

    const {data: themeRes} = useGetThemePaginationQuery({page: 0, limit: 100})
    const {data: accountData} = useGetAccountQuery(params.id)

    const [doSaveAccount, {isSuccess}] = useSaveAccountMutation()
    const [updatedAccount, setUpdatedAccount] = useState({})
    const [selectedTheme, setSelectedTheme] = useState({})

    const updateAccountTheme = (field, value) => {
        const theme = {...(updatedAccount?.theme || {})}

        Object.assign(theme, {[field]: value})
        setUpdatedAccount((prevState) => ({...prevState, theme}))
    }

    useEffect(() => {
        if (accountData) {
            if (accountData?.theme && accountData.theme?.theme) {
                setSelectedTheme(accountData.theme.theme)
            }

            setUpdatedAccount(accountData)
        }
    }, [accountData])

    const handleSaveAccount = (e) => {
        e?.preventDefault()
        const newAccount = Object.assign({}, updatedAccount)
        if (Object.keys(selectedTheme).length) {
            newAccount.theme = {...newAccount.theme, theme: selectedTheme}
        }

        doSaveAccount(newAccount)
            .unwrap()
            .catch((r) => {
                onError(r?.data?.message)
            })
    }

    const redirectToList = useCallback(
        (redirect = false) => redirect && history.goBack(),
        [history]
    )

    useEffect(() => {
        if (isSuccess) {
            const {message, type, redirect} = getSuccessSaveMessageData(
                updatedAccount?.name
            )

            showAlertMessage(message, type, dispatch)
            redirect && redirectToList(true)
        }
    }, [dispatch, isSuccess, redirectToList, updatedAccount])

    const onChangeTheme = (v) => {
        const theme = themeRes?.data?.find((t) => t._id === v)
        setSelectedTheme(theme)
    }

    const colorOptions = useMemo(() => {
        return themeRes?.data?.map((t) => ({
            value: t._id,
            label: t.name,
        }))
    }, [themeRes?.data])

    return (
        <div>
            <p>Account Theme Config</p>
            <Row>
                <Col sm={'12'}>
                    <SelectInput
                        label={'Theme'}
                        name={'theme'}
                        options={colorOptions}
                        selected={selectedTheme?._id}
                        onChange={onChangeTheme}
                    />
                </Col>
            </Row>

            <Row>
                <Col sm="6">
                    <FormGroup>
                        <Label>Loader Image URL</Label>
                        <AppInput
                            name="loaderImageUrl"
                            value={updatedAccount?.theme?.loaderImageUrl}
                            setter={(v) =>
                                updateAccountTheme('loaderImageUrl', v)
                            }
                        />
                    </FormGroup>
                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label>Logo Image URL</Label>
                        <AppInput
                            name="logoImageUrl"
                            value={updatedAccount?.theme?.logoImageUrl}
                            setter={(v) =>
                                updateAccountTheme('logoImageUrl', v)
                            }
                        />
                    </FormGroup>
                </Col>

                <Col sm="6">
                    <FormGroup>
                        <Label>Logo Dark Image URL</Label>
                        <AppInput
                            name="logoImageDarkUrl"
                            value={updatedAccount?.theme?.logoImageDarkUrl}
                            setter={(v) =>
                                updateAccountTheme('logoImageDarkUrl', v)
                            }
                        />
                    </FormGroup>
                </Col>

                <Col sm="6">
                    <FormGroup>
                        <Label>Icon URL</Label>
                        <AppInput
                            name="iconUrl"
                            value={updatedAccount?.theme?.iconUrl}
                            setter={(v) => updateAccountTheme('iconUrl', v)}
                        />
                    </FormGroup>
                </Col>

                <Col sm="6">
                    <FormGroup>
                        <Label>Icon Dark URL</Label>
                        <AppInput
                            name="iconDarkUrl"
                            value={updatedAccount?.theme?.iconDarkUrl}
                            setter={(v) => updateAccountTheme('iconDarkUrl', v)}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Row className="d-flex justify-content-center pt-4">
                <Button
                    id="save_button"
                    color="primary"
                    type="submit"
                    onClick={handleSaveAccount}>
                    Save Account
                </Button>
            </Row>
        </div>
    )
}
export default AccountDetail
