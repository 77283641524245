import {createApi} from '@reduxjs/toolkit/query/react'
import {
    axiosBaseQuery,
    transformResponseFromData,
    provideTags,
    provideTag,
} from 'store/common'

const categoryApi = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: 'category',
    tagTypes: ['Categories', 'Category'],
    endpoints: (build) => ({
        getCategoryPagination: build.query({
            query: (params) => ({
                url: 'admin/category',
                method: 'GET',
                params,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('Categories', data?.data),
        }),
        getCategoryById: build.query({
            query: ({id, language = 'en'}) => ({
                url: `admin/category/${id}?language=${language}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Category', data, '_id')],
        }),
        saveCategory: build.mutation({
            query: (data) => ({
                url: 'admin/category',
                method: 'POST',
                body: data,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Category', data, '_id')],
            invalidatesTags: (_, __, data) => [
                {type: 'Categories', id: data?._id},
                {type: 'Category', id: data?._id},
            ],
        }),
    }),
})

export default categoryApi

export const {
    useGetCategoryPaginationQuery,
    useGetCategoryByIdQuery,
    useSaveCategoryMutation,
} = categoryApi
