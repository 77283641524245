import {React, styled} from 'common'
import {Input} from 'reactstrap'

const AppInput = ({
    type = 'text',
    name,
    id,
    placeholder,
    value = '',
    pattern,
    inputMode,
    setter,
    showError,
    preventDefault = true,
    setInputFocused,
    disableAutoComplete,
    disable,
    marginbottom,
    ...props
}) => {
    const isEmptyInput =
        typeof value === 'string' ? !value.trim().length : !value
    const showErrorClass = showError && isEmptyInput && 'border-danger'
    return (
        <StyledInput
            id={id}
            disabled={disable}
            marginbottom={marginbottom}
            className={showErrorClass}
            type={type}
            name={name}
            pattern={pattern}
            inputMode={inputMode}
            placeholder={placeholder}
            autoComplete={disableAutoComplete && 'off'}
            value={value}
            onFocus={() => setInputFocused && setInputFocused(true)}
            onBlur={() => setInputFocused && setInputFocused(false)}
            onChange={(e) => {
                preventDefault && e.preventDefault()
                setter(e.target.value)
            }}
            {...props}
        />
    )
}

export default AppInput

const StyledInput = styled(Input)`
    ${({marginbottom}) =>
        marginbottom >= 0 && `margin-bottom: ${marginbottom}px !important`};
    :focus {
        border-color: #00f2c3;
    }
`
