import React, {useState} from 'react'
import {DetailsWrapper, Page, PageContent} from 'components/general/Page'
import MessageAlert from 'components/general/Display/MessageAlert'
import ThemeDetail from 'components/Theme/ThemeDetail'

const Theme = () => {
    const [showAlert, setShowAlert] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const onError = (error) => {
        setShowAlert(true)
        setErrorMessage(error)
    }

    return (
        <Page>
            <PageContent>
                <DetailsWrapper
                    header="Theme Detail"
                    backButtonText="Back to Theme List">
                    <ThemeDetail onError={onError} />
                </DetailsWrapper>
            </PageContent>

            <MessageAlert
                showAlert={showAlert}
                title={'Failed To Save'}
                mainText={errorMessage}
                confirmBtnText="OK"
                onConfirm={() => setShowAlert(false)}
            />
        </Page>
    )
}
export default Theme
