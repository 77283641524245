import {createApi} from '@reduxjs/toolkit/query/react'
import {
    axiosBaseQuery,
    transformResponseFromData,
    provideTags,
    provideTag,
} from 'store/common'

const themeApi = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: 'theme',
    tagTypes: ['ThemePagination', 'Theme'],
    endpoints: (build) => ({
        getThemePagination: build.query({
            query: (params) => ({
                url: 'admin/theme',
                method: 'GET',
                params,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => provideTags('ThemePagination', data?.data),
        }),
        getThemeById: build.query({
            query: ({id}) => ({
                url: `admin/theme/${id}`,
                method: 'GET',
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Theme', data, '_id')],
        }),
        saveTheme: build.mutation({
            query: (data) => ({
                url: 'admin/theme',
                method: 'POST',
                body: data,
            }),
            transformResponse: transformResponseFromData,
            providesTags: (data) => [provideTag('Theme', data, '_id')],
            invalidatesTags: (_, __, data) => [
                {type: 'ThemePagination', id: data?._id},
                {type: 'Theme', id: data?._id},
            ],
        }),
        setDefaultTheme: build.mutation({
            query: (data) => ({
                url: 'admin/theme/default',
                method: 'POST',
                body: data,
            }),
            transformResponse: transformResponseFromData,
            invalidatesTags: () => [{type: 'ThemePagination'}, {type: 'Theme'}],
        }),
    }),
})

export default themeApi

export const {
    useGetThemePaginationQuery,
    useGetThemeByIdQuery,
    useSaveThemeMutation,
    useSetDefaultThemeMutation,
} = themeApi
