export const initialData = {
    primaryColor: null,
    secondaryColor: null,
    tertiaryColor: null,
    forthColor: null,
    linearPrimary: null,
    linearSecondary: null,
    colors: {
        greyScale: {
            surface: {
                subtle: null,
                default: null,
                disabled: null,
            },
            border: {
                default: null,
                disabled: null,
                darker: null,
            },
            textIcon: {
                title: null,
                body: null,
                subtitle: null,
                caption: null,
                negative: null,
                disabled: null,
            },
        },
        primary: {
            surface: {
                subtle: null,
                lighter: null,
                default: null,
                darker: null,
            },
            border: {
                subtle: null,
                lighter: null,
                default: null,
                darker: null,
            },
            textIcon: {
                label: null,
            },
        },
        error: {
            surface: {
                subtle: null,
                lighter: null,
                default: null,
                darker: null,
            },
            border: {
                subtle: null,
                lighter: null,
                default: null,
                darker: null,
            },
            textIcon: {
                label: null,
            },
        },
        info: {
            surface: {
                subtle: null,
                lighter: null,
                default: null,
                darker: null,
            },
            border: {
                subtle: null,
                lighter: null,
                default: null,
                darker: null,
            },
            textIcon: {
                label: null,
            },
        },
        success: {
            surface: {
                subtle: null,
                lighter: null,
                default: null,
                darker: null,
            },
            border: {
                subtle: null,
                lighter: null,
                default: null,
                darker: null,
            },
            textIcon: {
                label: null,
            },
        },
        green: null,
        red: null,
        blue: null,
        charocal: null,
        desert: null,
    },
    typography: {
        H1Bold: null,
        H2Bold: null,
        H3Bold: null,
        H4Bold: null,
        HeadlineBold: null,
        BodyBold: null,
        SubtitleBold: null,
        CaptionBold: null,
        FootnoteBold: null,
        H1Regular: null,
        H2Regular: null,
        H3Regular: null,
        H4Regular: null,
        HeadlineRegular: null,
        SubtitleRegular: null,
        CaptionRegular: null,
        FootnoteRegular: null,
        BodyRegular: null,
        H1Light: null,
        H2Light: null,
        H3Light: null,
        H4Light: null,
        HeadlineLight: null,
        BodyLight: null,
        SubtitleLight: null,
        FontBlack: null,
        FontBlackItalic: null,
        FontBold: null,
        FontBoldItalic: null,
        FontExtraBold: null,
        FontExtraBoldItalic: null,
        FontExtraLight: null,
        FontExtraLightItalic: null,
        FontItalic: null,
        FontLight: null,
        FontLightItalic: null,
        FontMedium: null,
        FontMediumItalic: null,
        FontRegular: null,
        FontSemiBold: null,
        FontSemiBoldItalic: null,
        FontThin: null,
        FontThinItalic: null,
    },
    calendar: {
        selectedColor: null,
        todayColor: null,
    },
    header: {
        backgroundColor: null,
    },
    name: null,
}
