import {levelOptions} from 'constant/workout'
import SelectInput from 'components/general/Control/SelectInput'
import {Col} from 'reactstrap'

const LevelOptions = ({level, setLevel, showError}) => {
    return (
        <Col>
            <SelectInput
                required
                showError={showError}
                selected={level}
                onChange={setLevel}
                options={levelOptions}
                name="level"
                label="Level"
            />
        </Col>
    )
}

export default LevelOptions
